<!--
 * @Author: your name
 * @Date: 2021-07-30 11:32:27
 * @LastEditTime: 2021-09-18 10:52:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\serve\index.vue
-->
<template>
  <div class="serve">
    <div class="container">
      <div class="search flex-h flex-vc">
        <h4>找组织</h4>
        <div class="searchBox flex-1">
          <input type="text" v-model="fieldKey" />
          <div
            class="searchBtn flex-h flex-hc flex-vc"
            @click="
              currentIndex = 0;
              filtration = 0;
              organizationType = '';
              currPage = 1;
              getList();
            "
          >
            <img src="../../assets/img/search.png" />
            <span>搜索</span>
          </div>
        </div>
      </div>
      <div class="status">
        <div class="formItem flex-h">
          <div class="label">组织类型</div>
          <div class="tabs">
            <span
              class="tab"
              @click="changeType(index, item.id)"
              :class="{ active: currentIndex == index }"
              v-for="(item, index) in tabs"
              :key="index"
              >{{ item.title }}</span
            >
          </div>
        </div>
        <div class="formItem flex-h">
          <div class="label">智能筛选</div>
          <div class="tabs">
            <span
              class="tab"
              :class="{ active: filtration == 0 }"
              @click="changeStatus(0)"
              >全部</span
            >
            <span
              class="tab"
              :class="{ active: filtration == 1 }"
              @click="changeStatus(1)"
              >活动次数最多</span
            >
            <span
              class="tab"
              :class="{ active: filtration == 2 }"
              @click="changeStatus(2)"
              >人数最多</span
            >
          </div>
        </div>
      </div>
      <ul class="serveList flex-h">
        <li
          class="flex-h"
          v-for="(item, index) in list"
          :key="index"
          @click="
            $router.push({ name: 'serveDetail', query: { id: item.queueId } })
          "
        >
          <div class="itemLeft">
            <img class="teamImg" :src="item.queueLogo" />
            <div class="startList flex-h flex-hc flex-vc">
              <img
                src="@/assets/img/start.png"
                v-for="(item, index) in item.score"
                :key="index"
              />
            </div>
          </div>
          <div class="itemRight">
            <h1>{{ item.queueName }}</h1>
            <p>服务队编号：{{ item.queueCode }}</p>
            <p>队员人数：{{ item.memberNum }}人</p>
            <div class="tags">
              主要服务方向：
                <span v-if="item.serviceOrientation && item.serviceOrientation[0]">{{ item.serviceOrientation[0].length>6?item.serviceOrientation[0].substr(0,5)+"...":item.serviceOrientation[0]}}</span>
              <span v-if="item.serviceOrientation && item.serviceOrientation[1]">{{ item.serviceOrientation[1].length>6?item.serviceOrientation[1].substr(0,5)+"...":item.serviceOrientation[1] }}</span>
              <span v-if="item.serviceOrientation && item.serviceOrientation[2]">{{ item.serviceOrientation[2].length>6?item.serviceOrientation[2].substr(0,5)+"...":item.serviceOrientation[2]  }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="Pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-size="limit"
          layout=" prev, pager,  next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { organizationList, queueList } from "@/api/index";
export default {
  data() {
    return {
      tabs: [],
      filtration: 0,
      organizationType: "",
      fieldKey: "",
      currentIndex: 0,
      limit: 10,
      total: 0,
      currPage: 1,
      list: [],
    };
  },
  methods: {
    getList() {
      queueList({
        addrId: this.location.addrId,
        currPage: this.currPage,
        filtration: this.filtration,
        pageLimit: this.limit,
        organizationType: this.organizationType,
        fieldKey: this.fieldKey,
      }).then((res) => {
          this.list = res.data.list;
          this.total = res.data.totalCount;
      });
    },
    getTypeList() {
      organizationList().then((res) => {
        this.tabs = [{ id: "", title: "全部" }, ...res.data.list];
        this.getList();
      });
    },
    changeStatus(filtration) {
      this.filtration = filtration;
      this.currPage = 1;
      this.getList();
    },
    changeType(index, id) {
      this.currentIndex = index;
      this.organizationType = id;
      this.currPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currPage = val;
      this.getList();
    },
  },
  created() {
    this.getTypeList();
  },
  watch: {
    location(newV, oldV) {
      this.getList();
    },
  },
  computed: {
    location() {
      return this.$store.state.location;
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style-type: none
}
    .serve {
        background: #f6f6f6;
        padding: 20px 0;

        .search {
            h4 {
                font-size: 22px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #fb3947;
            }

            .searchBox {
                margin-left: 14px;
                height: 47px;
                background: #ffffff;
                border: 1px solid #ff6e6e;

                input {
                    width: calc(100% - 93px);
                    line-height: 47px;
                    font-size: 20px;
                    text-indent: 20px;
                }

                .searchBtn {
                    float: right;
                    width: 93px;
                    height: 100%;
                    background: #ff6e6e;
                    cursor: pointer;

                    img {
                        width: 21px;
                    }

                    span {
                        margin-left: 2px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 47px;
                    }
                }
            }
        }

        .status {
            padding: 20px;
            background: #ffffff;
            border-radius: 13px;
            margin-top: 23px;
            margin-bottom: 30px;

            .formItem {
                margin-bottom: 10px;

                .label {
                    font-size: 15px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #737373;
                    white-space: nowrap;
                    height: 29px;
                    line-height: 29px;
                }

                .tabs {
                    margin-left: 28px;

                    .tab {
                        display: inline-block;
                        height: 29px;
                        border-radius: 15px;
                        cursor: pointer;
                        font-size: 15px;
                        font-family: MicrosoftYaHei;
                        color: #8f8f8f;
                        line-height: 29px;
                        padding: 0 20px;
                        margin-bottom: 10px;

                        &.active {
                            background: #ff6e6e;
                            color: #fff;
                        }
                    }
                }

                .button {
                    width: 80px;
                    height: 32px;
                    background: #ff6e6e;
                    border-radius: 16px;
                    text-align: center;
                    line-height: 32px;
                    cursor: pointer;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }
            }
        }

        .serveList {
            flex-wrap: wrap;

            li {
                cursor: pointer;
                width: 570px;
                height: 266px;
                background: #ffffff;
                box-shadow: 0px 2px 4px 0px #e9e5e5;
                border-radius: 10px;
                margin-bottom: 36px;

                &:nth-child(odd) {
                    margin-right: 60px;
                }

                .itemLeft {
                    width: 205px;
                    padding-top: 30px;

                    .teamImg {
                        width: 138px;
                        height: 138px;
                        border-radius: 50%;
                        display: block;
                        margin: 0 auto;
                    }

                    .startList {
                        margin-top: 29px;

                        img {
                            width: 20px;
                            margin: 0 5px;
                        }
                    }
                }

                .itemRight {
                    padding-top: 39px;

                    h1 {
                        width: 352px;
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #000000;
                        margin-bottom: 11px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    p {
                        width: 352px;
                        margin-bottom: 11px;
                        font-size: 20px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #737373;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .tags {
                        font-size: 20px;
                        width: 352px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #737373;

                        span {
                            display: inline-block;
                            height: 31px;
                            font-size: 20px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #fff;
                            line-height: 31px;
                            text-align: center;
                            background: #90ddd4;
                            border-radius: 16px;
                            padding: 0 14px;
                            margin-bottom: 9px;
                            margin-right: 14px;
                        }
                    }
                }
            }
        }

        .Pagination {
            text-align: center;
        }
    }
</style>